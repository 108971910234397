import type { LinkProps } from '@remix-run/react'
import * as R from 'remeda'
import { type Locale, PREFIXED_PATHS_BY_LOCALE } from '~/i18n/config'

export const cleanAnyLocalePrefixFromLinkTo = (
  to: LinkProps['to']
): LinkProps['to'] => {
  const localesPrefixRegExp = R.pipe(
    PREFIXED_PATHS_BY_LOCALE,
    R.values(),
    R.map((lang) => {
      return lang.replace('/', '\\/')
    }),
    R.join('|'),
    (pattern) => {
      return new RegExp(`(${pattern})`)
    }
  )

  if (typeof to === 'object') {
    return {
      ...to,
      pathname: to.pathname?.replace(localesPrefixRegExp, '')
    }
  }

  return to.replace(localesPrefixRegExp, '')
}

export function matchIsLinkToStartWithLocalePrefix(
  to: LinkProps['to'],
  locale: Locale
) {
  const prefixedLocalePath = PREFIXED_PATHS_BY_LOCALE[locale]

  if (typeof to === 'object') {
    return to.pathname?.startsWith(prefixedLocalePath)
  }

  return to.startsWith(prefixedLocalePath)
}

export function setLocalePrefixToLinkTo(
  to: LinkProps['to'],
  locale: Locale
): LinkProps['to'] {
  const prefixedLocalePath = PREFIXED_PATHS_BY_LOCALE[locale]
  const toCleaned = cleanAnyLocalePrefixFromLinkTo(to) as typeof to

  if (typeof to === 'object') {
    const toCleanedObject = toCleaned as typeof to

    return {
      ...toCleanedObject,
      pathname: `${prefixedLocalePath}${toCleanedObject.pathname}`
    }
  }

  return `${prefixedLocalePath}${toCleaned}`
}

export const ROUTES = {
  checkup: '/check-up',
  followup: '/follow-up',
  faq: '/faq',
  team: '/team',
  companies: '/companies',
  subscription_booking: '/subscription/booking',
  subscription_myAccount: '/subscription/my-account',
  subscription_payment: '/subscription/payment',
  subscription_confirmation: '/subscription/confirmation',
  bookingCompanies_form: '/booking-company/contact',
  bookingCompanies_confirm: '/booking-company/confirm',
  contactUs: '/contact-us/form',
  contactUs_confirm: '/contact-us/confirm',
  jobs: '/recruitment',
  cookiesNotice: '/cookies-notice',
  legalMentions: '/legal-mentions',
  privacyNotice: '/privacy-notice',
  manifesto: '/manifesto',
  research: '/research',
  signup: '/account/signup',
  login: '/account/login',
  logout: '/account/logout',
  noticeKit_cui: '/notice-kit/cui',
  noticeKit_cub: '/notice-kit/cub'
} as const
